import { Dispatch } from 'react';
import * as at from './actionTypes';
import actionCreator, { Actions } from '../../util/actionCreator';
import { MovieResponse } from '../../../server/shared/movie';
import {
  isErrorResponse,
  MoviesApi,
  StatusesApi,
} from '../../../server/shared/client';
import { StatusResponse } from '../../../server/shared/status';
import { EmojiType } from '../../components/Emoji';

export interface MoviesWithStatus {
  status: string;
  order: number;
  movies: MovieResponse[];
}

export const searchMoviesActions = {
  searchMoviesStart: () => actionCreator(at.SEARCH_MOVIES_START),
  searchMoviesEnd: (movies: MoviesWithStatus[]) =>
    actionCreator(at.SEARCH_MOVIES_END, movies),
  searchMoviesError: (error: string) =>
    actionCreator(at.SEARCH_MOVIES_ERROR, error),
  getStatusesStart: () => actionCreator(at.GET_STATUSES_START),
  getStatusesEnd: (statuses: StatusResponse[]) =>
    actionCreator(at.GET_STATUSES_END, statuses),
  getStatusesError: (error: string) =>
    actionCreator(at.GET_STATUSES_ERROR, error),
  addReactionStart: () => actionCreator(at.ADD_REACTION_START),
  addReactionEnd: () => actionCreator(at.ADD_REACTION_END),
  addReactionError: (error: string) =>
    actionCreator(at.ADD_REACTION_ERROR, error),
  updateMovieStart: () => actionCreator(at.UPDATE_MOVIE_START),
  updateMovieEnd: () => actionCreator(at.UPDATE_MOVIE_END),
  updateMovieError: (error: string) =>
    actionCreator(at.UPDATE_MOVIE_ERROR, error),
  setMovieModal: (movie: MovieResponse | null) =>
    actionCreator(at.SET_MOVIE_MODAL, movie),
  setQuery: (query: string) => actionCreator(at.SET_QUERY, query),
};

export type SearchMoviesActions = Actions<typeof searchMoviesActions>;

export const searchMovies = async (
  query: string,
  dispatch: Dispatch<SearchMoviesActions>,
) => {
  if (query.length < 3) {
    dispatch(
      searchMoviesActions.searchMoviesError(
        'Please enter more than 3 characters',
      ),
    );
    return;
  }

  dispatch(searchMoviesActions.searchMoviesStart());

  const response = await MoviesApi.search(query);

  if (isErrorResponse(response)) {
    dispatch(
      searchMoviesActions.searchMoviesError(
        'Could not find the requested movie',
      ),
    );
    return;
  }

  const moviesWithStatus: Record<string, MoviesWithStatus> = response.reduce(
    (sorted, m) => {
      const existing = sorted[m.status.displayName];

      if (existing) {
        return {
          ...sorted,
          [m.status.displayName]: {
            ...existing,
            movies: [...existing.movies, m],
          },
        };
      }
      return {
        ...sorted,
        [m.status.displayName]: {
          status: m.status.displayName,
          order: m.status.order,
          movies: [m],
        },
      };
    },
    {} as Record<string, MoviesWithStatus>,
  );

  const movies = Object.keys(moviesWithStatus)
    .map((k) => moviesWithStatus[k])
    .sort((a, b) => a.order - b.order);

  dispatch(searchMoviesActions.searchMoviesEnd(movies));
};

export const getStatuses = async (dispatch: Dispatch<SearchMoviesActions>) => {
  dispatch(searchMoviesActions.getStatusesStart());
  const resp = await StatusesApi.getAll();
  if (isErrorResponse(resp)) {
    dispatch(searchMoviesActions.getStatusesError('Could not fetch statuses'));
    return;
  }
  dispatch(searchMoviesActions.getStatusesEnd(resp));
};

export const markAsWatched = async (
  movieId: string,
  query: string,
  statuses: StatusResponse[],
  dispatch: Dispatch<SearchMoviesActions>,
) => {
  const targetStatus = statuses.find((s) => s.order === 2);
  if (!targetStatus) {
    return;
  }

  dispatch(searchMoviesActions.updateMovieStart());
  const resp = await MoviesApi.update(movieId, { statusId: targetStatus.id });

  if (isErrorResponse(resp)) {
    dispatch(
      searchMoviesActions.updateMovieError(
        'Could not update the status to watched',
      ),
    );
    return;
  }

  dispatch(searchMoviesActions.updateMovieEnd());

  if (query) {
    await searchMovies(query, dispatch);
  }
};

export const addReaction = async (
  reaction: EmojiType,
  movieId: string,
  query: string,
  dispatch: Dispatch<SearchMoviesActions>,
) => {
  dispatch(searchMoviesActions.addReactionStart());
  const resp = await MoviesApi.updateReaction(movieId, { reaction });
  if (isErrorResponse(resp)) {
    dispatch(searchMoviesActions.addReactionError('Could not add reaction'));
    return;
  }
  dispatch(searchMoviesActions.addReactionEnd());

  if (query) {
    await searchMovies(query, dispatch);
  }
};
