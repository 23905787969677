import { EmojiType } from '../components/Emoji';
import { MovieResponse } from '../../server/shared/movie';

const getAvailableReactions = (movie: MovieResponse): EmojiType[] => {
  if (!movie) {
    return [];
  }

  if (movie.status.order === 0) {
    return ['thumbsUp', 'thumbsDown'];
  }

  if (movie.status.order === 1) {
    return ['checkMark', 'crossMark'];
  }

  return [
    'poop',
    'faceVomiting',
    'loudlyCryingFace',
    'yawningFace',
    'slightSmile',
    'grinningFace',
    'partyFace',
    'startStruck',
  ];
};

export default getAvailableReactions;
