/**
 * Returns function that will only be called after the debounce time has elapsed. If
 * the function is repeteadly called before the time has elapsed, the timer will
 * reset.
 * @param {function} func         Function to debounce
 * @param {number}   debounceTime Time to wait, in milliseconds, before calling func
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = <T extends (...args: any) => any>(
  func: T,
  debounceTime: number,
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return (...args: Parameters<T>) => {
    const later = () => {
      timeout = null;
      func.apply({}, args);
    };
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, debounceTime);
  };
};

export default debounce;
