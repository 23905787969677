import React from 'react';
import styled from 'styled-components';
import Emoji, { EmojiType } from '../../../components/Emoji';
import theme from '../../../theme';
import Modal from '../../../components/Modal';
import { MovieResponse } from '../../../../server/shared/movie';
import getAvailableReactions from '../../../util/getAvailableReactions';

interface Props {
  movie: MovieResponse | null;
  onBackdropClick: NonNullable<React.HTMLAttributes<HTMLDivElement>['onClick']>;
  onReaction: (r: EmojiType) => void;
}

const Wrapper = styled.div`
  background-color: ${theme.neutral.black.hex()};
  padding: 12px;
  border-radius: 4px;
  justify-content: center;
  pointer-events: initial;
  border: 1px solid ${theme.tertiary.t500.hex()};
  margin: 0 4px 48px 4px;
  max-width: 100%;
  overflow: hidden;
`;

const Title = styled.h3`
  font-size: 16px;
  margin-top: 0;
  color: ${theme.neutral.n100.hex()};
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 8px;
  overflow: auto;
`;

const ReactButton = styled.button`
  border: 0;
  display: flex;
  padding: 4px;
  background-color: ${theme.neutral.n900.hex()};
  cursor: pointer;
  border-radius: 4px;
  z-index: 2;

  &:disabled {
    background-color: ${theme.accent.a500.hex()};
    cursor: default;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const modalTitle = (movie: Props['movie']) => {
  if (!movie) {
    return null;
  }

  if (movie.status.order === 0) {
    return (
      <Title>
        Approve <em>{movie.name}</em>?
      </Title>
    );
  }

  if (movie.status.order === 1) {
    return (
      <Title>
        Mark <em>{movie.name}</em> as watched?
      </Title>
    );
  }

  return (
    <Title>
      What did you think of <em>{movie.name}</em>?
    </Title>
  );
};

const ReactionModal = (props: Props) => {
  const { movie, onBackdropClick, onReaction } = props;

  return (
    <Modal
      modalId="addReaction"
      show={movie !== null}
      onBackdropClick={onBackdropClick}
      verticalPosition="bottom"
    >
      <Wrapper>
        {modalTitle(movie)}
        <ButtonContainer>
          {(movie ? getAvailableReactions(movie) : []).map((r) => (
            <ReactButton
              type="button"
              key={r}
              disabled={movie?.userReaction?.reaction === r}
              onClick={() => onReaction(r)}
            >
              <Emoji emoji={r} />
            </ReactButton>
          ))}
        </ButtonContainer>
      </Wrapper>
    </Modal>
  );
};

export default ReactionModal;
