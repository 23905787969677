import React from 'react';
import emojiManifest, { EmojiType } from './emojiManifest';

const EMOJI_SIZES = {
  tiny: '20px',
  small: '24px',
  medium: '32px',
  large: '36px',
};

interface Props {
  emoji: EmojiType;
  size?: keyof typeof EMOJI_SIZES;
  className?: string;
}

const Emoji = (props: Props) => {
  const { emoji, size = 'small', ...rest } = props;

  return (
    <svg
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      width={EMOJI_SIZES[size]}
      height={EMOJI_SIZES[size]}
      {...rest}
    >
      {emojiManifest[emoji]}
    </svg>
  );
};

export default Emoji;
