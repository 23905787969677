import { MovieResponse } from '../../../server/shared/movie';
import { StatusResponse } from '../../../server/shared/status';
import { MoviesActions } from './actions';
import * as at from './actionTypes';

interface MoviesState {
  getStatusesLoading: boolean;
  statuses: StatusResponse[];
  getStatusesError: string | null;
  getMoviesLoading: boolean;
  movies: MovieResponse[];
  getMoviesError: string | null;
  addReactionLoading: boolean;
  addReactionError: string | null;
  updateMovieLoading: boolean;
  updateMovieError: string | null;
  selectedStatus: StatusResponse | null;
  movieModal: MovieResponse | null;
  page: {
    currentPage: number;
    pageSize: number;
    order: 'ASC' | 'DESC';
    totalCount: number;
    pageCount: number;
  };
}

export const moviesDefaultState: MoviesState = {
  getStatusesLoading: false,
  statuses: [],
  getStatusesError: null,
  getMoviesLoading: false,
  movies: [],
  getMoviesError: null,
  addReactionLoading: false,
  addReactionError: null,
  updateMovieLoading: false,
  updateMovieError: null,
  selectedStatus: null,
  movieModal: null,
  page: {
    currentPage: 1,
    pageSize: 10,
    order: 'ASC',
    totalCount: 0,
    pageCount: 0,
  },
};

const moviesReducer = (
  state: MoviesState,
  action: MoviesActions,
): MoviesState => {
  switch (action.type) {
    case at.GET_STATUSES_START:
      return {
        ...state,
        getStatusesLoading: true,
      };
    case at.GET_STATUSES_END:
      return {
        ...state,
        getStatusesLoading: false,
        statuses: action.payload,
        getStatusesError: null,
      };
    case at.GET_STATUSES_ERROR:
      return {
        ...state,
        getStatusesLoading: false,
        statuses: [],
        getStatusesError: action.payload,
      };
    case at.GET_MOVIES_START:
      return {
        ...state,
        getMoviesLoading: true,
      };
    case at.GET_MOVIES_END:
      return {
        ...state,
        getMoviesLoading: false,
        movies: action.payload.data,
        getMoviesError: null,
        page: {
          currentPage: action.payload.currentPage,
          pageSize: action.payload.pageSize,
          order: action.payload.order,
          totalCount: action.payload.totalCount,
          pageCount: action.payload.pageCount,
        },
      };
    case at.GET_MOVIES_ERROR:
      return {
        ...state,
        getMoviesLoading: false,
        movies: [],
        getMoviesError: action.payload,
      };
    case at.ADD_REACTION_START:
      return {
        ...state,
        addReactionLoading: true,
      };
    case at.ADD_REACTION_END:
      return {
        ...state,
        addReactionLoading: false,
        addReactionError: null,
      };
    case at.ADD_REACTION_ERROR:
      return {
        ...state,
        addReactionLoading: false,
        addReactionError: action.payload,
      };
    case at.UPDATE_MOVIE_START:
      return {
        ...state,
        updateMovieLoading: true,
      };
    case at.UPDATE_MOVIE_END:
      return {
        ...state,
        updateMovieLoading: false,
        updateMovieError: null,
      };
    case at.UPDATE_MOVIE_ERROR:
      return {
        ...state,
        updateMovieLoading: false,
        updateMovieError: action.payload,
      };
    case at.SET_MOVIE_MODAL:
      return {
        ...state,
        movieModal: action.payload,
      };
    case at.SET_STATUS:
      return {
        ...state,
        selectedStatus: action.payload,
      };
    default:
      return state;
  }
};

export default moviesReducer;
