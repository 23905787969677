import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import Icon from '../Icon';

const MAX_PAGES = 7;

interface Props {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  onPageClick: (page: number, pageSize: number) => void;
}

const Wrapper = styled.div``;

const Pages = styled.div`
  display: flex;
`;

const PagePlaceholder = styled.p`
  margin: 0 8px 0 0;
  color: ${theme.primary.p500.hex()};
  background-color: transparent;
  border: 0;
  padding: 4px;
  height: 28px;
  min-width: 28px;
  border-radius: 4px;
`;

const PageButton = styled.button`
  color: ${theme.primary.p500.hex()};
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 4px;
  height: 28px;
  min-width: 28px;
  margin-right: 8px;
  border-radius: 4px;

  &:disabled {
    border: 1px solid ${theme.primary.p600.hex()};
    cursor: default;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ArrowButtons = styled(PageButton)`
  svg {
    fill: ${theme.primary.p500.hex()};
  }

  &:disabled {
    border: 0;

    svg {
      fill: ${theme.primary.p600.hex()};
    }
  }
`;

const generatePageButton = (
  currentPage: number,
  page: number,
  pageSize: number,
  callback: Props['onPageClick'],
) => (
  <PageButton
    key={page}
    onClick={() => callback(page, pageSize)}
    disabled={currentPage === page}
  >
    {page}
  </PageButton>
);

const generatePageButtons = (
  currentPage: number,
  totalPages: number,
  pageSize: number,
  callback: Props['onPageClick'],
) => {
  if (totalPages <= MAX_PAGES) {
    return [...Array(totalPages)].map((_, i) =>
      generatePageButton(currentPage, i + 1, pageSize, callback),
    );
  }

  if (currentPage < MAX_PAGES - 2) {
    const startingPages = [...Array(MAX_PAGES - 2)].map((_, i) =>
      generatePageButton(currentPage, i + 1, pageSize, callback),
    );
    return [
      ...startingPages,
      <PagePlaceholder key="placeholder-1">...</PagePlaceholder>,
      generatePageButton(currentPage, totalPages, pageSize, callback),
    ];
  }

  if (currentPage + MAX_PAGES - 4 >= totalPages) {
    const endingPages = [...Array(MAX_PAGES - 2)]
      .map((_, i) =>
        generatePageButton(currentPage, totalPages - i, pageSize, callback),
      )
      .reverse();
    return [
      generatePageButton(currentPage, 1, pageSize, callback),
      <PagePlaceholder key="placeholder-1">...</PagePlaceholder>,
      ...endingPages,
    ];
  }

  return [
    generatePageButton(currentPage, 1, pageSize, callback),
    <PagePlaceholder key="placeholder-1">...</PagePlaceholder>,
    generatePageButton(currentPage, currentPage - 1, pageSize, callback),
    generatePageButton(currentPage, currentPage, pageSize, callback),
    generatePageButton(currentPage, currentPage + 1, pageSize, callback),
    <PagePlaceholder key="placeholder-2">...</PagePlaceholder>,
    generatePageButton(currentPage, totalPages, pageSize, callback),
  ];
};

const Paginator = (props: Props) => {
  const { currentPage, pageSize, totalPages, onPageClick } = props;
  return (
    <Wrapper>
      <Pages>
        <ArrowButtons
          type="button"
          onClick={() => onPageClick(currentPage - 1, pageSize)}
          disabled={currentPage <= 1}
        >
          <Icon icon="previous" />
        </ArrowButtons>

        {generatePageButtons(currentPage, totalPages, pageSize, onPageClick)}

        <ArrowButtons
          type="button"
          onClick={() => onPageClick(currentPage + 1, pageSize)}
          disabled={currentPage >= totalPages}
        >
          <Icon icon="next" />
        </ArrowButtons>
      </Pages>
    </Wrapper>
  );
};

export default Paginator;
