import { MovieResponse } from '../../server/shared/movie';
import { MovieActions } from '../actions/movieActions';

interface MovieState {
  lastMovieAdded: MovieResponse | null;
  addMovieError: string | null;
  addMovieLoading: boolean;
}

const defaultMovieState: MovieState = {
  lastMovieAdded: null,
  addMovieError: null,
  addMovieLoading: false,
};

const movieReducer = (
  state = defaultMovieState,
  action: MovieActions,
): MovieState => {
  switch (action.type) {
    case 'ADD_MOVIE_START':
      return {
        ...state,
        addMovieLoading: true,
      };
    case 'ADD_MOVIE_END':
      return {
        ...state,
        addMovieLoading: false,
        lastMovieAdded: action.payload,
        addMovieError: null,
      };
    case 'ADD_MOVIE_ERROR':
      return {
        ...state,
        addMovieLoading: false,
        addMovieError: action.payload,
      };
    default:
      return state;
  }
};

export default movieReducer;
