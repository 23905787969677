import { Dispatch } from 'react';
import actionCreator, { Actions } from '../../util/actionCreator';
import * as at from './actionTypes';
import {
  isErrorResponse,
  MoviesApi,
  StatusesApi,
} from '../../../server/shared/client';
import { StatusResponse } from '../../../server/shared/status';
import { MovieResponse } from '../../../server/shared/movie';
import { EmojiType } from '../../components/Emoji';
import { PaginatedResponse } from '../../../server/shared/utility';

export const moviesActions = {
  getStatusesStart: () => actionCreator(at.GET_STATUSES_START),
  getStatusesEnd: (statuses: StatusResponse[]) =>
    actionCreator(at.GET_STATUSES_END, statuses),
  getStatusesError: (error: string) =>
    actionCreator(at.GET_STATUSES_ERROR, error),
  getMoviesStart: () => actionCreator(at.GET_MOVIES_START),
  getMoviesEnd: (movies: PaginatedResponse<MovieResponse>) =>
    actionCreator(at.GET_MOVIES_END, movies),
  getMoviesError: (error: string) => actionCreator(at.GET_MOVIES_ERROR, error),
  addReactionStart: () => actionCreator(at.ADD_REACTION_START),
  addReactionEnd: () => actionCreator(at.ADD_REACTION_END),
  addReactionError: (error: string) =>
    actionCreator(at.ADD_REACTION_ERROR, error),
  updateMovieStart: () => actionCreator(at.UPDATE_MOVIE_START),
  updateMovieEnd: () => actionCreator(at.UPDATE_MOVIE_END),
  updateMovieError: (error: string) =>
    actionCreator(at.UPDATE_MOVIE_ERROR, error),
  setMovieModal: (movie: MovieResponse | null) =>
    actionCreator(at.SET_MOVIE_MODAL, movie),
  setStatus: (status: StatusResponse) => actionCreator(at.SET_STATUS, status),
  setMovieName: (movieName: string) =>
    actionCreator(at.SET_MOVIE_NAME, movieName),
};

export type MoviesActions = Actions<typeof moviesActions>;

export interface PaginationOptions {
  page: number;
  pageSize: number;
  order: 'ASC' | 'DESC';
}

export const getMoviesByStatus = async (
  statusId: string,
  options: PaginationOptions,
  dispatch: Dispatch<MoviesActions>,
) => {
  dispatch(moviesActions.getMoviesStart());
  const resp = await MoviesApi.getByStatus(statusId, options);

  if (isErrorResponse(resp)) {
    dispatch(moviesActions.getMoviesError('Could not fetch movies'));
    return;
  }
  dispatch(moviesActions.getMoviesEnd(resp));
};

export const changeStatus = async (
  status: StatusResponse,
  options: Omit<PaginationOptions, 'page'>,
  dispatch: Dispatch<MoviesActions>,
) => {
  dispatch(moviesActions.setStatus(status));
  await getMoviesByStatus(status.id, { ...options, page: 1 }, dispatch);
};

export const getStatuses = async (
  options: PaginationOptions,
  dispatch: Dispatch<MoviesActions>,
) => {
  dispatch(moviesActions.getStatusesStart());
  const resp = await StatusesApi.getAll();
  if (isErrorResponse(resp)) {
    dispatch(moviesActions.getStatusesError('Could not fetch statuses'));
    return;
  }
  const sortedStatuses = resp.sort((s1, s2) => s1.order - s2.order);
  dispatch(moviesActions.getStatusesEnd(sortedStatuses));

  if (sortedStatuses.length > 0) {
    await changeStatus(sortedStatuses[0], options, dispatch);
  }
};

export const addReaction = async (
  reaction: EmojiType,
  movieId: string,
  selectedStatus: StatusResponse | null,
  options: PaginationOptions,
  dispatch: Dispatch<MoviesActions>,
) => {
  dispatch(moviesActions.addReactionStart());
  const resp = await MoviesApi.updateReaction(movieId, { reaction });
  if (isErrorResponse(resp)) {
    dispatch(moviesActions.addReactionError('Could not add reaction'));
    return;
  }
  dispatch(moviesActions.addReactionEnd());

  if (selectedStatus) {
    await getMoviesByStatus(selectedStatus.id, options, dispatch);
  }
};

export const markAsWatched = async (
  movieId: string,
  statuses: StatusResponse[],
  selectedStatus: StatusResponse | null,
  options: PaginationOptions,
  dispatch: Dispatch<MoviesActions>,
) => {
  const targetStatus = statuses.find((s) => s.order === 2);
  if (!targetStatus) {
    return;
  }

  dispatch(moviesActions.updateMovieStart());
  const resp = await MoviesApi.update(movieId, { statusId: targetStatus.id });

  if (isErrorResponse(resp)) {
    dispatch(
      moviesActions.updateMovieError('Could not update the status to watched'),
    );
    return;
  }

  dispatch(moviesActions.updateMovieEnd());

  if (selectedStatus) {
    await getMoviesByStatus(selectedStatus.id, options, dispatch);
  }
};
