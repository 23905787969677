import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './redux';
import { selectToken, selectUser } from '../selectors/sessionSelectors';
import { getStoredTokenInfo, isLoggedIn } from '../util/auth';
import { getCurrentUser, sessionActions } from '../actions/sessionActions';

const useRouteAuthInfo = () => {
  const token = useAppSelector(selectToken);
  const memoryToken = getStoredTokenInfo();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const location = useLocation();

  const fetchUserInfo = () => {
    if (isLoggedIn(token) && !user) {
      dispatch(getCurrentUser());
    }
  };

  const setTokenInfo = () => {
    if (isLoggedIn(memoryToken) && memoryToken && !token) {
      dispatch(sessionActions.getTokenEnd(memoryToken));
    }
  };

  useEffect(fetchUserInfo, [token, user, dispatch]);
  useEffect(setTokenInfo, [memoryToken, token, dispatch]);

  const hasValidToken = isLoggedIn(token || memoryToken);

  return {
    targetRoute: location.pathname,
    isLoggedIn: hasValidToken,
    isFetchingUser: user === null && hasValidToken,
  };
};

export default useRouteAuthInfo;
