import React from 'react';
import { IconManifest, iconManifest } from './iconManifest';
import theme, { colorStates } from '../../theme';

const SIZES = {
  tiny: '20px',
  small: '24px',
  medium: '32px',
  large: '36px',
};

interface Props {
  icon: IconManifest;
  color?: 'primary' | 'dark' | 'light' | 'accent' | 'danger';
  size?: keyof typeof SIZES;
}

const getHexColor = (color: NonNullable<Props['color']>) => {
  switch (color) {
    case 'dark':
      return theme.neutral.n500.hex();
    case 'light':
      return theme.neutral.n100.hex();
    case 'primary':
      return colorStates.primary.default.hex();
    case 'accent':
      return colorStates.accent.default.hex();
    case 'danger':
      return colorStates.danger.default.hex();
    default:
      throw new Error(`${color} is not a valid icon color`);
  }
};

const Icon = (props: Props) => {
  const { icon, size = 'small', color = 'light' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={SIZES[size]}
      height={SIZES[size]}
      fill={getHexColor(color)}
    >
      {iconManifest[icon]}
    </svg>
  );
};

export default Icon;
