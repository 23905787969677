import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import AuthRoute from '../components/AuthRoute';
import Movies from './Movies';
import BottomNav from '../components/BottomNav';
import SearchMovies from './SearchMovies';

const AUTH_ROUTES = {
  movies: '/movies',
  search: '/search',
};

const ALL_ROUTES = (
  Object.keys(AUTH_ROUTES) as (keyof typeof AUTH_ROUTES)[]
).map((k) => AUTH_ROUTES[k]);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const PageContent = styled.div<{ childHeight: number }>`
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;

  .page-content-child {
    height: ${({ childHeight }) => childHeight}px;
  }
`;

const AuthPages = () => {
  // TODO: Figure out why chome height is wrong with flexbox
  const pageContent = useRef<HTMLDivElement | null>(null);
  const contentHeight = pageContent.current?.clientHeight;
  const [currentHeight, setCurrentHeight] = useState(-1);

  useEffect(() => {
    if (contentHeight !== currentHeight) {
      setCurrentHeight(contentHeight || 0);
    }
  }, [contentHeight]);

  return (
    <Wrapper>
      <PageContent
        ref={pageContent}
        className="pageContent"
        childHeight={currentHeight}
      >
        <AuthRoute path={ALL_ROUTES}>
          <Switch>
            <Route path={AUTH_ROUTES.movies}>
              <Movies />
            </Route>

            <Route path={AUTH_ROUTES.search}>
              <SearchMovies />
            </Route>
          </Switch>
        </AuthRoute>
      </PageContent>
      <BottomNav />
    </Wrapper>
  );
};

export default AuthPages;
