import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import Input from '../Input';
import theme from '../../theme';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { addMovie } from '../../actions/movieActions';
import {
  selectAddMovieError,
  selectAddMovieLoading,
  selectLastMovieAdded,
} from '../../selectors/movieSelectors';

interface Props {
  show: boolean;
  onBackdropClick: NonNullable<React.HTMLAttributes<HTMLDivElement>['onClick']>;
}

const Wrapper = styled.form`
  pointer-events: initial;
  padding: 12px;

  input {
    &:focus {
      background-color: ${theme.neutral.black.hex()};
    }
  }
`;

const NewMovieOverlay = (props: Props) => {
  const { show, onBackdropClick } = props;
  const [movieName, setMovieName] = useState('');
  const reduxDispatch = useAppDispatch();
  const addMovieLoading = useAppSelector(selectAddMovieLoading);
  const addMovieError = useAppSelector(selectAddMovieError);
  const lastMovieAdded = useAppSelector(selectLastMovieAdded);

  useEffect(() => {
    if (lastMovieAdded && !addMovieError && !addMovieLoading) {
      setMovieName('');
    }
  }, [lastMovieAdded, addMovieError, addMovieLoading]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (!addMovieLoading) {
      reduxDispatch(addMovie(movieName));
    }
  };

  return (
    <Modal
      show={show}
      modalId="addMovie"
      onBackdropClick={onBackdropClick}
      verticalPosition="bottom"
    >
      <Wrapper onSubmit={onSubmit}>
        <Input
          autoFocus
          label="Movie name"
          name="movieName"
          placeholder="Suggest a movie"
          onChange={(v) => setMovieName(v)}
          value={movieName}
          error={addMovieError}
        />
      </Wrapper>
    </Modal>
  );
};

export default NewMovieOverlay;
