import React, { ChangeEvent, forwardRef } from 'react';
import styled from 'styled-components';
import theme, { colorStates, rgba } from '../../theme';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label: string;
  name: string;
  error?: string | null;
  onChange?: (value: string) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-width: 350px;
  width: 100%;
  margin-bottom: 8px;
  text-align: left;
  position: relative;
`;

const Label = styled.label`
  font-size: 12px;
  text-transform: capitalize;
  padding-bottom: 4px;
  font-weight: 600;
  transition: color 150ms ease;
`;

interface InputProps {
  hasError: boolean;
}

const StyledInput = styled.input<InputProps>`
  border: 1px solid
    ${(props) =>
      props.hasError
        ? colorStates.danger.default.hex()
        : theme.neutral.n600.hex()};
  border-radius: 6px;
  font-size: 16px;
  padding: 4px 8px;
  outline: none;
  background-color: transparent;
  color: ${theme.neutral.n100.hex()};
  width: 100%;
  margin-bottom: 20px;
  transition: background-color 150ms ease, border-color 150ms ease,
    color 150ms ease;

  &:hover,
  &:focus {
    box-shadow: 0 0 11px 1px ${theme.neutral.black.hex()};
  }

  &:focus {
    background-color: ${({ hasError }) =>
      hasError ? rgba(colorStates.danger.default, 0.1) : 'transparent'};

    & + ${Label} {
      color: ${theme.neutral.n100.hex()};
    }
  }

  & + ${Label} {
    color: ${({ hasError }) =>
      hasError
        ? `${colorStates.danger.default.hex()} !important`
        : theme.neutral.n300.hex()};
  }

  &:disabled {
    background-color: ${theme.neutral.n900.hex()};
    border-color: ${theme.neutral.n900.hex()};
    color: ${theme.neutral.n500.hex()};
    box-shadow: none;

    & + ${Label} {
      color: ${theme.neutral.n700.hex()};
    }
  }
`;

const ErrorMessage = styled.p`
  color: ${colorStates.danger.default.hex()};
  font-size: 12px;
  margin: 0;
  position: absolute;
  bottom: 0;
`;

const renderMessage = (error: Props['error']) => {
  if (!error) {
    return null;
  }

  return <ErrorMessage>{error}</ErrorMessage>;
};

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    name,
    label,
    error,
    onChange: propsOnChange,
    type: inputType = 'text',
    ...rest
  } = props;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (propsOnChange) {
      propsOnChange(e.target.value);
    }
  };

  return (
    <Container>
      {renderMessage(error)}
      <StyledInput
        ref={ref}
        id={name}
        name={name}
        onChange={onChange}
        type={inputType}
        hasError={!!error}
        {...rest}
      />
      <Label htmlFor={name}>{label}</Label>
    </Container>
  );
});

export default Input;
