import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ROUTES from './routes';
import Login from './pages/Login';
import { useAppSelector } from './hooks/redux';
import { selectModalOpen } from './selectors/uiSelectors';
import AuthPages from './pages/AuthPages';

const blurStyling = css`
  filter: blur(2px);
`;

interface AppWrapperProps {
  modalOpen: boolean;
}

const AppWrapper = styled.div<AppWrapperProps>`
  height: 100%;
  ${({ modalOpen }) => (modalOpen ? blurStyling : '')};
`;

const App = () => {
  const modalOpen = useAppSelector(selectModalOpen);

  return (
    <AppWrapper modalOpen={modalOpen !== null}>
      <BrowserRouter>
        <Switch>
          <Route exact path={ROUTES.login}>
            <Login />
          </Route>

          <AuthPages />

          <Route exact path="/">
            <Redirect to={ROUTES.movies} />
          </Route>

          <Route> 404 not found </Route>
        </Switch>
      </BrowserRouter>
    </AppWrapper>
  );
};

export default App;
