import React from 'react';
import styled from 'styled-components';
import Emoji, { EmojiType } from '../Emoji';
import theme from '../../theme';

interface ReactionData {
  reaction: EmojiType;
  count: number;
  active: boolean;
}

interface Props {
  reactions: ReactionData[];
}

const Wrapper = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 4px;
  background-color: ${theme.neutral.n900.hex()};
  position: absolute;
  bottom: -16px;
  left: 8px;
`;

const ReactionWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.neutral.n200.hex()};

  &:not(:last-child) {
    margin-right: 8px;
  }

  p {
    margin: 0 0 0 4px;
  }

  &.active {
    p {
      color: ${theme.primary.p500.hex()};
      font-weight: 600;
    }
  }
`;

const MovieReactions = (props: Props) => {
  const { reactions } = props;

  if (reactions.length <= 0) {
    return null;
  }

  return (
    <Wrapper>
      {reactions.map((r) => (
        <ReactionWrapper
          key={r.reaction}
          className={r.active ? 'active' : undefined}
        >
          <Emoji emoji={r.reaction} size="tiny" />
          <p>{r.count}</p>
        </ReactionWrapper>
      ))}
    </Wrapper>
  );
};

export default MovieReactions;
