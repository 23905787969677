import { MovieResponse } from '../../server/shared/movie';
import actionCreator, { Actions } from '../util/actionCreator';
import { AppThunk } from '../store';
import { isErrorResponse, MoviesApi } from '../../server/shared/client';

const ADD_MOVIE_START = 'ADD_MOVIE_START';
const ADD_MOVIE_END = 'ADD_MOVIE_END';
const ADD_MOVIE_ERROR = 'ADD_MOVIE_ERROR';

const movieActions = {
  addMovieStart: () => actionCreator(ADD_MOVIE_START),
  addMovieEnd: (movie: MovieResponse) => actionCreator(ADD_MOVIE_END, movie),
  addMovieError: (error: string) => actionCreator(ADD_MOVIE_ERROR, error),
};

export type MovieActions = Actions<typeof movieActions>;

export const addMovie =
  (movieName: string): AppThunk =>
  async (dispatch) => {
    if (!movieName) {
      dispatch(movieActions.addMovieError('Please enter a movie name'));
      return;
    }

    dispatch(movieActions.addMovieStart());
    const response = await MoviesApi.create({ name: movieName });
    if (isErrorResponse(response)) {
      dispatch(movieActions.addMovieError('Could not add movie at this time'));
      return;
    }
    dispatch(movieActions.addMovieEnd(response));
  };
