import { TokenResponse } from '../../server/shared/token';
import { SessionActions } from '../actions/sessionActions';
import { UserResponse } from '../../server/shared/user';

interface SessionState {
  tokenLoading: boolean;
  tokenError: string | null;
  token: TokenResponse | null;
  userLoading: boolean;
  userError: string | null;
  user: UserResponse | null;
}

const defaultSessionState: SessionState = {
  tokenLoading: false,
  tokenError: null,
  token: null,
  userLoading: false,
  userError: null,
  user: null,
};

const sessionReducer = (
  state = defaultSessionState,
  action: SessionActions,
): SessionState => {
  switch (action.type) {
    case 'GET_TOKEN_START':
      return {
        ...state,
        tokenLoading: true,
        token: null,
      };
    case 'GET_TOKEN_ERROR':
      return {
        ...state,
        tokenLoading: false,
        token: null,
        tokenError: action.payload,
      };
    case 'GET_TOKEN_END':
      return {
        ...state,
        tokenLoading: false,
        token: action.payload,
        tokenError: null,
      };
    case 'GET_CURRENT_USER_START':
      return {
        ...state,
        userLoading: true,
        user: null,
      };
    case 'GET_CURRENT_USER_END':
      return {
        ...state,
        userLoading: false,
        userError: null,
        user: action.payload,
      };
    case 'GET_CURRENT_USER_ERROR':
      return {
        ...state,
        userLoading: false,
        user: null,
        userError: action.payload,
      };
    default:
      return state;
  }
};

export default sessionReducer;
