import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectModalOpen } from '../../selectors/uiSelectors';
import { closeModal, openModal } from '../../actions/uiActions';
import theme from '../../theme';

const modalRoot = document.getElementById('modal-root');

interface Props {
  show: boolean;
  modalId: string;
  verticalPosition?: 'top' | 'bottom' | 'center';
  allowClickThrough?: boolean;
  onBackdropClick?: React.HTMLAttributes<HTMLDivElement>['onClick'];
}

interface ModalWrapperProps {
  allowClickThrough: boolean;
}

const ModalWrapper = styled.div<ModalWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  border: 5px solid ${theme.neutral.n900.hex()};
  pointer-events: ${({ allowClickThrough }) =>
    allowClickThrough ? 'none' : 'initial'};
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

interface ModalContentProps {
  verticalPosition: NonNullable<Props['verticalPosition']>;
}

const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  pointer-events: none;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${({ verticalPosition }) => {
    if (verticalPosition === 'top') {
      return css``;
    }

    if (verticalPosition === 'bottom') {
      return css`
        align-items: flex-end;
      `;
    }

    return css`
      align-items: center;
    `;
  }}
`;

const Modal: React.FunctionComponent<Props> = (props) => {
  const {
    show,
    modalId,
    children,
    onBackdropClick,
    allowClickThrough = false,
    verticalPosition = 'center',
  } = props;
  const dispatch = useAppDispatch();
  const modalOpen = useAppSelector(selectModalOpen);
  const clickThrough = !onBackdropClick && allowClickThrough;

  useEffect(() => {
    if (show && modalOpen !== modalId) {
      dispatch(openModal(modalId));
    } else if (!show && modalOpen === modalId) {
      dispatch(closeModal());
    }

    return () => {
      if (modalOpen === modalId) {
        dispatch(closeModal());
      }
    };
  }, [show]);

  if (modalOpen !== modalId) {
    return null;
  }

  const renderModal = () => {
    if (onBackdropClick) {
      return (
        <ModalWrapper allowClickThrough={clickThrough}>
          <Backdrop onClick={onBackdropClick} />
          <ModalContent verticalPosition={verticalPosition}>
            {children}
          </ModalContent>
        </ModalWrapper>
      );
    }

    return (
      <ModalWrapper allowClickThrough={clickThrough}>
        <ModalContent verticalPosition={verticalPosition}>
          {children}
        </ModalContent>
      </ModalWrapper>
    );
  };

  return ReactDOM.createPortal(renderModal(), modalRoot!);
};

export default Modal;
