import * as React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
}

const Container = styled.button<Props>`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: ${theme.neutral.n200.hex()};
  display: inline-flex;
  background-color: transparent;
  margin: 0;
  padding: 12px 0;
  border: 0;
  outline: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &:hover {
    color: ${theme.neutral.n100.hex()};
  }

  &[aria-selected='true'] {
    color: ${theme.accent.a500.hex()};
    cursor: default;
  }
`;

const TabLink: React.FunctionComponent<Props> = (props) => {
  const { selected, children, disabled, ...rest } = props;
  return (
    <Container
      aria-selected={selected}
      disabled={selected || disabled}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default TabLink;
