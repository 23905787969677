import { UiActions } from '../actions/uiActions';

interface UiState {
  modalOpen: string | null;
}

const defaultUiState: UiState = {
  modalOpen: null,
};

const uiReducer = (state = defaultUiState, action: UiActions): UiState => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        modalOpen: action.payload,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modalOpen: null,
      };
    default:
      return state;
  }
};

export default uiReducer;
