import React from 'react';
import { format, toDate } from 'date-fns';
import styled from 'styled-components';
import { MovieResponse } from '../../../server/shared/movie';
import theme from '../../theme';
import Avatar from '../Avatar';
import Icon from '../Icon';
import MovieReactions from './MovieReactions';
import { EmojiType } from '../Emoji';
import getAvailableReactions from '../../util/getAvailableReactions';

const Movie = styled.div`
  padding: 12px;
  background-color: ${theme.neutral.black.hex()};
  max-width: 500px;
  width: 100%;
  border-radius: 4px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const MovieHeader = styled.section`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const MovieTitle = styled.h6`
  font-size: 18px;
  margin: 0;
  font-weight: 600;
`;

const MovieOwner = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  & > :first-child {
    margin-right: 8px;
  }
`;

const MovieDetails = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: ${theme.neutral.n200.hex()};
  border-top: 1px solid ${theme.neutral.n700.hex()};
  padding-top: 12px;

  p {
    margin: 0;
  }
`;

const IconButton = styled.button`
  display: flex;
  padding: 4px;
  background-color: transparent;
  cursor: pointer;
  border: 0;

  &:hover {
    svg {
      fill: ${theme.neutral.n200.hex()};
    }
  }
`;

interface Props {
  onAddReactionClick: () => void;
  movie: MovieResponse;
}

const sortReactions = (movie: MovieResponse) => {
  const reactions = getAvailableReactions(movie);

  return movie.reactions.sort(
    (r1, r2) =>
      reactions.indexOf(r1.reaction as EmojiType) -
      reactions.indexOf(r2.reaction as EmojiType),
  );
};

const formatAddedOn = (movie: MovieResponse) =>
  format(toDate(movie.watchedOn || movie.addedOn), 'MM/dd/yyyy h:mm aaa');

const MovieCard = (props: Props) => {
  const { movie, onAddReactionClick } = props;

  const reactionsMap = sortReactions(movie).reduce((all, r) => {
    if (all[r.reaction]) {
      return {
        ...all,
        [r.reaction]: all[r.reaction] + 1,
      };
    }
    return {
      ...all,
      [r.reaction]: 1,
    };
  }, {} as { [K: string]: number });

  const reactions = (Object.keys(reactionsMap) as EmojiType[]).map((r) => ({
    reaction: r,
    count: reactionsMap[r],
    active: movie.userReaction ? movie.userReaction.reaction === r : false,
  }));

  return (
    <Movie>
      <MovieHeader>
        <MovieTitle>{movie.name}</MovieTitle>
        <IconButton onClick={() => onAddReactionClick()}>
          <Icon icon="addReaction" />
        </IconButton>
      </MovieHeader>

      <MovieDetails>
        <p>{formatAddedOn(movie)}</p>

        <MovieOwner>
          <p>{movie.user.displayName}</p>
          <Avatar
            imageLink={movie.user.avatarLink}
            alt={`${movie.user.displayName} avatar`}
          >
            {movie.user.displayName}
          </Avatar>
        </MovieOwner>
      </MovieDetails>
      <MovieReactions reactions={reactions} />
    </Movie>
  );
};

export default MovieCard;
