export const SEARCH_MOVIES_START = 'SEARCH_MOVIES_START';
export const SEARCH_MOVIES_END = 'SEARCH_MOVIES_END';
export const SEARCH_MOVIES_ERROR = 'SEARCH_MOVIES_ERROR';

export const GET_STATUSES_START = 'GET_STATUSES_START';
export const GET_STATUSES_END = 'GET_STATUSES_END';
export const GET_STATUSES_ERROR = 'GET_STATUSES_ERROR';

export const ADD_REACTION_START = 'ADD_REACTION_START';
export const ADD_REACTION_END = 'ADD_REACTION_END';
export const ADD_REACTION_ERROR = 'ADD_REACTION_ERROR';

export const UPDATE_MOVIE_START = 'UPDATE_MOVIE_START';
export const UPDATE_MOVIE_END = 'UPDATE_MOVIE_END';
export const UPDATE_MOVIE_ERROR = 'UPDATE_MOVIE_ERROR';

export const SET_MOVIE_MODAL = 'SET_MOVIE_MODAL';
export const SET_QUERY = 'SET_QUERY';
