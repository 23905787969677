import actionCreator, { Actions } from '../util/actionCreator';

const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const uiActions = {
  openModal: (modalId: string) => actionCreator(OPEN_MODAL, modalId),
  closeModal: () => actionCreator(CLOSE_MODAL),
};

export type UiActions = Actions<typeof uiActions>;

export const openModal = (modalId: string) => uiActions.openModal(modalId);
export const closeModal = () => uiActions.closeModal();
