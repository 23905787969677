import React from 'react';

const emojiManifest = {
  checkMark: (
    <>
      <path
        fill="#77B255"
        d="M36 32c0 2.209-1.791 4-4 4H4c-2.209 0-4-1.791-4-4V4c0-2.209 1.791-4 4-4h28c2.209 0 4 1.791 4 4v28z"
      />
      <path
        fill="#FFF"
        d="M29.28 6.362c-1.156-.751-2.704-.422-3.458.736L14.936 23.877l-5.029-4.65c-1.014-.938-2.596-.875-3.533.138-.937 1.014-.875 2.596.139 3.533l7.209 6.666c.48.445 1.09.665 1.696.665.673 0 1.534-.282 2.099-1.139.332-.506 12.5-19.27 12.5-19.27.751-1.159.421-2.707-.737-3.458z"
      />
    </>
  ),
  crossMark: (
    <>
      <path
        fill="#DD2E44"
        d="M18 0C8.059 0 0 8.059 0 18s8.059 18 18 18 18-8.059 18-18S27.941 0 18 0zm13 18c0 2.565-.753 4.95-2.035 6.965L11.036 7.036C13.05 5.753 15.435 5 18 5c7.18 0 13 5.821 13 13zM5 18c0-2.565.753-4.95 2.036-6.964l17.929 17.929C22.95 30.247 20.565 31 18 31c-7.179 0-13-5.82-13-13z"
      />
    </>
  ),
  faceVomiting: (
    <>
      <path
        fill="#FFCB4C"
        d="M35 17c0 9.389-7.611 17-17 17-9.388 0-17-7.611-17-17C1 7.612 8.612 0 18 0c9.389 0 17 7.612 17 17"
      />
      <path
        fill="#65471B"
        d="M18 27.136c3.335 0 5.547.388 8.284.92.625.12 1.841 0 1.841-1.841 0-3.682-4.23-8.284-10.125-8.284S7.875 22.533 7.875 26.215c0 1.841 1.216 1.962 1.841 1.841 2.736-.532 4.949-.92 8.284-.92zM6.668 18.888c-.287 0-.571-.13-.756-.378-.313-.417-.229-1.009.189-1.322.135-.101 2.787-2.061 6.475-2.77-2.338-1.172-4.932-1.197-4.965-1.197-.521-.001-.943-.425-.942-.946 0-.521.422-.943.944-.943.209 0 5.149.036 8.223 3.11.27.27.351.676.205 1.029-.146.353-.491.583-.872.583-4.357 0-7.898 2.618-7.933 2.644-.173.129-.371.19-.568.19zm22.664 0c-.196 0-.395-.061-.564-.187-.036-.027-3.6-2.645-7.935-2.645-.382 0-.727-.23-.872-.583-.147-.353-.066-.759.205-1.029 3.075-3.075 8.015-3.11 8.223-3.11.521 0 .943.422.944.943.001.521-.422.944-.942.946-.034 0-2.629.025-4.966 1.197 3.688.708 6.34 2.668 6.475 2.77.417.313.502.903.19 1.321-.186.247-.47.377-.758.377z"
      />
      <path
        fill="#77AF57"
        d="M25.712 32.287v-7.101c0-2.593-3.889-5.185-7.778-5.185s-7.778 2.593-7.778 5.185v7.101c-4.403.349-7.359.985-7.359 1.713 0 1.105 6.777 2 15.137 2s15.137-.895 15.137-2c0-.728-2.956-1.364-7.359-1.713z"
      />
      <circle fill="#5D8F3F" cx="15.875" cy="23.228" r="1.235" />
      <circle fill="#5D8F3F" cx="20.698" cy="25.64" r="1.235" />
      <circle
        transform="rotate(-80.781 21.232 30.514)"
        fill="#5D8F3F"
        cx="21.232"
        cy="30.515"
        r="1.235"
      />
      <circle
        transform="rotate(-80.781 15.107 32.548)"
        fill="#5D8F3F"
        cx="15.107"
        cy="32.548"
        r="1.765"
      />
    </>
  ),
  grinningFace: (
    <>
      <circle fill="#FFCC4D" cx="18" cy="18" r="18" />
      <path
        fill="#664500"
        d="M18 21c-3.623 0-6.027-.422-9-1-.679-.131-2 0-2 2 0 4 4.595 9 11 9 6.404 0 11-5 11-9 0-2-1.321-2.132-2-2-2.973.578-5.377 1-9 1z"
      />
      <path fill="#FFF" d="M9 22s3 1 9 1 9-1 9-1-2 4-9 4-9-4-9-4z" />
      <ellipse fill="#664500" cx="12" cy="13.5" rx="2.5" ry="3.5" />
      <ellipse fill="#664500" cx="24" cy="13.5" rx="2.5" ry="3.5" />
    </>
  ),
  loudlyCryingFace: (
    <>
      <path
        fill="#FFCC4D"
        d="M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18"
      />
      <path
        fill="#664500"
        d="M22 27c0 2.763-1.791 3-4 3-2.21 0-4-.237-4-3 0-2.761 1.79-6 4-6 2.209 0 4 3.239 4 6zm8-12c-.124 0-.25-.023-.371-.072-5.229-2.091-7.372-5.241-7.461-5.374-.307-.46-.183-1.081.277-1.387.459-.306 1.077-.184 1.385.274.019.027 1.93 2.785 6.541 4.629.513.206.763.787.558 1.3-.157.392-.533.63-.929.63zM6 15c-.397 0-.772-.238-.929-.629-.205-.513.044-1.095.557-1.3 4.612-1.844 6.523-4.602 6.542-4.629.308-.456.929-.577 1.387-.27.457.308.581.925.275 1.383-.089.133-2.232 3.283-7.46 5.374C6.25 14.977 6.124 15 6 15z"
      />
      <path fill="#5DADEC" d="M24 16h4v19l-4-.046V16zM8 35l4-.046V16H8v19z" />
      <path
        fill="#664500"
        d="M14.999 18c-.15 0-.303-.034-.446-.105-3.512-1.756-7.07-.018-7.105 0-.495.249-1.095.046-1.342-.447-.247-.494-.047-1.095.447-1.342.182-.09 4.498-2.197 8.895 0 .494.247.694.848.447 1.342-.176.35-.529.552-.896.552zm14 0c-.15 0-.303-.034-.446-.105-3.513-1.756-7.07-.018-7.105 0-.494.248-1.094.047-1.342-.447-.247-.494-.047-1.095.447-1.342.182-.09 4.501-2.196 8.895 0 .494.247.694.848.447 1.342-.176.35-.529.552-.896.552z"
      />
      <ellipse fill="#5DADEC" cx="18" cy="34" rx="18" ry="2" />
      <ellipse fill="#E75A70" cx="18" cy="27" rx="3" ry="2" />
    </>
  ),
  partyFace: (
    <>
      <circle fill="#FFCC4D" cx="17" cy="19" r="17" />
      <ellipse fill="#664500" cx="17.999" cy="26" rx="2" ry="2.5" />
      <path
        fill="#664500"
        d="M8.111 21.383c-.182 0-.367-.05-.532-.154-.467-.294-.607-.911-.313-1.379.916-1.453 3.701-3.938 7.69-2.962.536.131.864.673.733 1.209-.132.536-.676.862-1.209.733-3.604-.882-5.502 2.056-5.521 2.086-.191.302-.516.467-.848.467zm11.973-3.742c-.29 0-.576-.125-.774-.366-.35-.427-.288-1.058.14-1.408 3.176-2.604 6.762-1.562 8.215-.646.467.294.607.912.312 1.379-.293.465-.908.607-1.376.315-.138-.084-3.052-1.823-5.884.499-.186.153-.41.227-.633.227z"
      />
      <path
        fill="#E2A62D"
        d="M13.346 31.273c-.068 0-.137-.009-.205-.028-.398-.113-.63-.527-.517-.926.437-1.54.258-3.029-.49-4.086-.497-.702-1.205-1.131-1.943-1.178-.414-.025-.728-.382-.702-.795s.381-.751.795-.701c1.193.074 2.313.733 3.073 1.807 1.011 1.429 1.27 3.383.709 5.361-.093.331-.394.546-.72.546zm11.037-3.061c-.142 0-.285-.04-.412-.124-1.167-.77-1.82-2.117-1.792-3.695.029-1.635.809-3.153 1.984-3.869.353-.216.814-.104 1.03.251.216.354.104.814-.251 1.03-.735.448-1.244 1.499-1.264 2.614-.02 1.055.389 1.936 1.118 2.417.346.228.441.693.213 1.039-.144.219-.382.337-.626.337z"
      />
      <path
        fill="#DD2E44"
        d="M17.179 2.72c-.043-.049-.11-.076-.189-.091 0 0-15.924-3.023-16.613-2.415C-.311.823.74 16.998.74 16.998c.005.081.023.15.067.199.604.684 4.758-2.004 9.279-6.001 4.522-3.998 7.697-7.792 7.093-8.476z"
      />
      <path
        fill="#EA596E"
        d="M.349.271C.334.301.321.342.311.394.47 1.765 2.006 13.046 2.963 16.572c1.436-.803 2.895-1.894 4.609-3.253C6.116 10.654 1.158.146.349.271z"
      />
      <path
        fill="#3B88C3"
        d="M29.902 29.229l-10.573-1.303c-1.13-.102-3.117-.112-3.015-1.902.093-1.623 2.04-1.373 3.479-1.16l10.638 1.774-.529 2.591z"
      />
      <path
        fill="#88C9F9"
        d="M30.43 26.639l-4.222-.724c-.494-.089-.934.647-.956 1.426-.025.866.227 1.304.726 1.406l4.144.512.308-2.62z"
      />
      <path
        fill="#3B88C3"
        d="M34.918 26.341l-2.622 2.411-4.687-5.097 2.622-2.411c1.361-1.252 3.499-1.162 4.751.199l.135.147c1.251 1.362 1.162 3.499-.199 4.751z"
      />
      <ellipse
        transform="rotate(-42.597 29.954 26.205)"
        fill="#88C9F9"
        cx="29.952"
        cy="26.203"
        rx="2.77"
        ry="3.462"
      />
      <ellipse
        transform="rotate(-42.597 29.954 26.205)"
        fill="#269"
        cx="29.952"
        cy="26.203"
        rx="1.385"
        ry="2.077"
      />
      <circle fill="#55ACEE" cx="2.5" cy="33.5" r="1.5" />
      <circle fill="#55ACEE" cx="29" cy="2" r="2" />
      <path
        fill="#EA596E"
        d="M4.864 29.246L2.526 23.63.412 29.675zM26 5l-4 1 1-4z"
      />
      <path fill="#77B255" d="M31.999 13L36 7.999 33 6z" />
    </>
  ),
  poop: (
    <>
      <path
        fill="#BF6952"
        d="M33.541 23.198c.364-1.578.243-3.266-.458-4.946-.678-1.625-1.847-2.91-3.271-3.773.318-1.192.234-2.475-.324-3.75-.841-1.92-2.66-3.201-4.712-3.562.249-.572.329-1.289.036-2.167-1-3-5-1-8-4.999-2.44 1.464-2.97 3.64-2.878 5.487-2.421.412-3.8.936-3.8.936v.002c-1.36.55-2.322 1.883-2.322 3.442 0 .879.318 1.676.828 2.312l-.692.258.001.003c-2.33.871-3.975 2.976-3.975 5.439 0 1.047.3 2.027.82 2.878C1.971 22.027 0 24.781 0 28c0 4.418 3.691 8 8.244 8 3.269 0 6.559-.703 9.531-1.665C20.018 35.375 23.47 36 28.667 36 32.717 36 36 32.717 36 28.667c0-2.176-.953-4.125-2.459-5.469z"
      />
      <ellipse fill="#F5F8FA" cx="13.5" cy="15.5" rx="3.5" ry="4.5" />
      <ellipse fill="#F5F8FA" cx="23.5" cy="15.5" rx="3.5" ry="4.5" />
      <ellipse fill="#292F33" cx="14" cy="15.5" rx="2" ry="2.5" />
      <ellipse fill="#292F33" cx="23" cy="15.5" rx="2" ry="2.5" />
      <path
        fill="#292F33"
        d="M9.447 24.895C9.201 24.402 9.45 24 10 24h18c.55 0 .799.402.553.895C28.553 24.895 26 30 19 30s-9.553-5.105-9.553-5.105z"
      />
      <path
        fill="#F2ABBA"
        d="M19 26c-2.771 0-5.157.922-6.292 2.256C14.2 29.211 16.253 30 19 30s4.801-.789 6.292-1.744C24.157 26.922 21.771 26 19 26z"
      />
    </>
  ),
  slightSmile: (
    <>
      <circle fill="#FFCC4D" cx="18" cy="18" r="18" />
      <path
        fill="#664500"
        d="M10.515 23.621C10.56 23.8 11.683 28 18 28c6.318 0 7.44-4.2 7.485-4.379.055-.217-.043-.442-.237-.554-.195-.111-.439-.078-.6.077C24.629 23.163 22.694 25 18 25s-6.63-1.837-6.648-1.855C11.256 23.05 11.128 23 11 23c-.084 0-.169.021-.246.064-.196.112-.294.339-.239.557z"
      />
      <ellipse fill="#664500" cx="12" cy="13.5" rx="2.5" ry="3.5" />
      <ellipse fill="#664500" cx="24" cy="13.5" rx="2.5" ry="3.5" />
    </>
  ),
  startStruck: (
    <>
      <path
        fill="#FFCC4D"
        d="M36 18c0 9.941-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0s18 8.059 18 18"
      />
      <path
        fill="#664500"
        d="M18 21c-3.623 0-6.027-.422-9-1-.679-.131-2 0-2 2 0 4 4.595 9 11 9 6.404 0 11-5 11-9 0-2-1.321-2.132-2-2-2.973.578-5.377 1-9 1z"
      />
      <path fill="#FFF" d="M9 22s3 1 9 1 9-1 9-1-2 4-9 4-9-4-9-4z" />
      <path
        fill="#E95F28"
        d="M15.682 4.413l-4.542.801L8.8.961C8.542.492 8.012.241 7.488.333c-.527.093-.937.511-1.019 1.039l-.745 4.797-4.542.801c-.535.094-.948.525-1.021 1.064s.211 1.063.703 1.297l4.07 1.932-.748 4.812c-.083.536.189 1.064.673 1.309.179.09.371.133.562.133.327 0 .65-.128.891-.372l3.512-3.561 4.518 2.145c.49.232 1.074.123 1.446-.272.372-.395.446-.984.185-1.459L13.625 9.73l3.165-3.208c.382-.387.469-.977.217-1.459-.254-.482-.793-.743-1.325-.65zm4.636 0l4.542.801L27.2.961c.258-.469.788-.72 1.312-.628.526.093.936.511 1.018 1.039l.745 4.797 4.542.801c.536.094.949.524 1.021 1.063s-.211 1.063-.703 1.297l-4.07 1.932.748 4.812c.083.536-.189 1.064-.673 1.309-.179.09-.371.133-.562.133-.327 0-.65-.128-.891-.372l-3.512-3.561-4.518 2.145c-.49.232-1.074.123-1.446-.272-.372-.395-.446-.984-.185-1.459l2.348-4.267-3.165-3.208c-.382-.387-.469-.977-.217-1.459.255-.482.794-.743 1.326-.65z"
      />
    </>
  ),
  thumbsDown: (
    <>
      <path
        fill="#FFDB5E"
        d="M34.956 18.084c0 .503-.12.975-.321 1.404-1.341 4.326-7.619 4.01-16.549 4.221-1.493.035-.639 1.798-.115 5.668.341 2.517-1.282 6.382-4.01 6.382-4.498 0-.171-3.548-4.148-12.322-2.125-4.688-6.875-2.062-6.875-6.771V5.948c0-1.833.18-3.595 2.758-3.885C8.195 1.781 7.633 0 11.238 0h18.044c1.838 0 3.333 1.496 3.333 3.334 0 .762-.267 1.456-.698 2.018 1.02.571 1.72 1.649 1.72 2.899 0 .76-.266 1.454-.696 2.015 1.023.57 1.725 1.649 1.725 2.901 0 .909-.368 1.733-.961 2.336.757.611 1.251 1.535 1.251 2.581z"
      />
      <path
        fill="#EE9547"
        d="M23.02 14.751h8.604c1.17 0 2.268.626 2.866 1.633.246.415.109.952-.307 1.199-.415.247-.952.108-1.199-.307-.283-.479-.806-.775-1.361-.775h-8.81c-.873 0-1.583.71-1.583 1.583s.71 1.583 1.583 1.583H28.7c.483 0 .875.392.875.875s-.392.875-.875.875h-5.888c-1.838 0-3.333-1.495-3.333-3.333 0-1.025.475-1.932 1.205-2.544-.615-.605-.998-1.445-.998-2.373 0-1.028.478-1.938 1.212-2.549-.611-.604-.99-1.441-.99-2.367 0-1.12.559-2.108 1.409-2.713-.524-.589-.852-1.356-.852-2.204 0-1.838 1.495-3.333 3.333-3.333h5.484c1.17 0 2.269.625 2.867 1.632.247.415.11.952-.305 1.199-.416.245-.953.11-1.199-.305-.285-.479-.808-.776-1.363-.776h-5.484c-.873 0-1.583.71-1.583 1.583s.71 1.583 1.583 1.583h6.506c1.17 0 2.27.626 2.867 1.633.247.416.11.953-.305 1.199-.419.251-.954.11-1.199-.305-.289-.487-.799-.777-1.363-.777h-7.063c-.873 0-1.583.711-1.583 1.584s.71 1.583 1.583 1.583h8.091c1.17 0 2.269.625 2.867 1.632.247.415.11.952-.305 1.199-.417.246-.953.11-1.199-.305-.289-.486-.799-.776-1.363-.776H23.02c-.873 0-1.583.71-1.583 1.583s.709 1.584 1.583 1.584z"
      />
    </>
  ),
  thumbsUp: (
    <>
      <path
        fill="#FFDB5E"
        d="M34.956 17.916c0-.503-.12-.975-.321-1.404-1.341-4.326-7.619-4.01-16.549-4.221-1.493-.035-.639-1.798-.115-5.668.341-2.517-1.282-6.382-4.01-6.382-4.498 0-.171 3.548-4.148 12.322-2.125 4.688-6.875 2.062-6.875 6.771v10.719c0 1.833.18 3.595 2.758 3.885C8.195 34.219 7.633 36 11.238 36h18.044c1.838 0 3.333-1.496 3.333-3.334 0-.762-.267-1.456-.698-2.018 1.02-.571 1.72-1.649 1.72-2.899 0-.76-.266-1.454-.696-2.015 1.023-.57 1.725-1.649 1.725-2.901 0-.909-.368-1.733-.961-2.336.757-.611 1.251-1.535 1.251-2.581z"
      />
      <path
        fill="#EE9547"
        d="M23.02 21.249h8.604c1.17 0 2.268-.626 2.866-1.633.246-.415.109-.952-.307-1.199-.415-.247-.952-.108-1.199.307-.283.479-.806.775-1.361.775h-8.81c-.873 0-1.583-.71-1.583-1.583s.71-1.583 1.583-1.583H28.7c.483 0 .875-.392.875-.875s-.392-.875-.875-.875h-5.888c-1.838 0-3.333 1.495-3.333 3.333 0 1.025.475 1.932 1.205 2.544-.615.605-.998 1.445-.998 2.373 0 1.028.478 1.938 1.212 2.549-.611.604-.99 1.441-.99 2.367 0 1.12.559 2.108 1.409 2.713-.524.589-.852 1.356-.852 2.204 0 1.838 1.495 3.333 3.333 3.333h5.484c1.17 0 2.269-.625 2.867-1.632.247-.415.11-.952-.305-1.199-.416-.245-.953-.11-1.199.305-.285.479-.808.776-1.363.776h-5.484c-.873 0-1.583-.71-1.583-1.583s.71-1.583 1.583-1.583h6.506c1.17 0 2.27-.626 2.867-1.633.247-.416.11-.953-.305-1.199-.419-.251-.954-.11-1.199.305-.289.487-.799.777-1.363.777h-7.063c-.873 0-1.583-.711-1.583-1.584s.71-1.583 1.583-1.583h8.091c1.17 0 2.269-.625 2.867-1.632.247-.415.11-.952-.305-1.199-.417-.246-.953-.11-1.199.305-.289.486-.799.776-1.363.776H23.02c-.873 0-1.583-.71-1.583-1.583s.709-1.584 1.583-1.584z"
      />
    </>
  ),
  yawningFace: (
    <>
      <path
        fill="#FFCC4D"
        d="M35 17c0 9.389-7.611 17-17 17-9.388 0-17-7.611-17-17C1 7.612 8.612 0 18 0c9.389 0 17 7.612 17 17"
      />
      <ellipse fill="#664500" cx="18" cy="19.5" rx="7" ry="7.5" />
      <path
        fill="#F4900C"
        d="M22.468 27.371s.997-.721.165-1.614c-.832-.893-1.621.052-1.621.052l-4.148 3.866c-.069-.205-.459-.743-.55-.947l5.755-5.366s.998-.721.164-1.615c-.832-.892-1.622.051-1.622.051l-5.413 5.046c-.125-.158-.461-.54-.594-.698l6.276-5.85s.997-.722.164-1.614c-.832-.893-1.621.051-1.621.051l-6.278 5.848c-.15-.145-.612-.609-.764-.746l5.866-5.468s.996-.722.164-1.614c-.833-.893-1.621.051-1.621.051l-6.203 5.781-.497.464-.22.207s-.538.744-1.087.179l1.842-4.158s.58-1.074-.493-1.655c-1.075-.581-1.655.493-1.655.493l-1.668 2.758c-.663 1.093-1.349 2.178-2.159 3.167-2.284 2.793-2.211 6.914.318 9.629 2.759 2.959 7.396 3.122 10.355.364l.447-.418 6.698-6.244z"
      />
      <path
        fill="#664500"
        d="M11.016 6.08c-2.255.604-3.48 1.965-3.555 2.05-.364.415-.323 1.047.091 1.412.415.365 1.046.325 1.411-.091.079-.087 2.09-2.253 5.81-1.492.542.111 1.07-.239 1.18-.779.111-.541-.238-1.07-.779-1.18-1.635-.336-3.026-.223-4.158.08zm13.968.001c-1.132-.303-2.523-.416-4.159-.082-.541.111-.89.639-.779 1.18.112.541.639.89 1.18.779 3.724-.762 5.731 1.405 5.814 1.497.367.407 1 .444 1.41.078.41-.366.451-.991.088-1.404-.075-.084-1.3-1.444-3.554-2.048z"
      />
    </>
  ),
};

export type EmojiType = keyof typeof emojiManifest;

export default emojiManifest;
