import React from 'react';
import styled from 'styled-components';
import { MoviesWithStatus } from '../actions';
import MovieCard from '../../../components/MovieCard';
import theme from '../../../theme';
import { MovieResponse } from '../../../../server/shared/movie';

interface Props {
  statusList: MoviesWithStatus;
  openMovieModal: (movie: MovieResponse) => void;
}

const Wrapper = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  h3 {
    font-size: 16px;
  }
`;

const Title = styled.h3<{ statusColor: string }>`
  font-size: 16px;
  margin: 0 0 8px 0;
  padding-bottom: 4px;
  color: ${({ statusColor }) => statusColor};
  border-bottom: 1px solid ${({ statusColor }) => statusColor};
`;

const getStatusColor = (status: string) => {
  switch (status) {
    case 'Suggested':
      return theme.red.r500.hex();
    case 'Pending':
      return theme.accent.a500.hex();
    case 'Watched':
      return theme.blue.b500.hex();
    default:
      return theme.neutral.white.hex();
  }
};

const StatusMovieList = (props: Props) => {
  const { openMovieModal, statusList } = props;

  return (
    <Wrapper>
      <Title statusColor={getStatusColor(statusList.status)}>
        {statusList.status}
      </Title>
      {statusList.movies.map((m) => (
        <MovieCard
          onAddReactionClick={() => openMovieModal(m)}
          movie={m}
          key={m.id}
        />
      ))}
    </Wrapper>
  );
};

export default StatusMovieList;
