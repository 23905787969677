import { isFuture, toDate } from 'date-fns';
import { TokenResponse } from '../../server/shared/token';

export const isLoggedIn = (token: TokenResponse | null) =>
  token && isFuture(toDate(token.exp));

export const getStoredTokenInfo = () => {
  const tokenInfo = window.localStorage.getItem('token');

  if (!tokenInfo) {
    return null;
  }

  try {
    return JSON.parse(tokenInfo) as TokenResponse;
  } catch {
    return null;
  }
};
