import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import MovieCard from '../../components/MovieCard/MovieCard';
import Tabs from '../../components/Tabs';
import TabLink from '../../components/Tabs/TabLink';
import moviesReducer, { moviesDefaultState } from './reducer';
import {
  addReaction,
  changeStatus,
  getMoviesByStatus,
  getStatuses,
  markAsWatched,
  moviesActions,
  PaginationOptions,
} from './actions';
import ReactionModal from './components/ReactionModal';
import { EmojiType } from '../../components/Emoji';
import { useAppSelector } from '../../hooks/redux';
import { selectLastMovieAdded } from '../../selectors/movieSelectors';
import Paginator from '../../components/Paginator';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background-color: ${theme.neutral.n900.hex()};
  color: white;
  font-size: 16px;
`;

const Statuses = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const MovieList = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const MovieListScroll = styled.div`
  max-height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 12px 24px 12px;
`;

const PageInfo = styled.p`
  font-size: 14px;
  color: ${theme.neutral.n400.hex()};
  text-align: right;
  max-width: 500px;
  width: 100%;
  margin-top: 0;
`;

const renderPageInfo = (
  page: number,
  pageSize: number,
  totalItems: number,
  pageCount: number,
) => {
  if (pageCount <= 0) {
    return <PageInfo>Showing 0 of 0</PageInfo>;
  }

  const start = pageSize * (page - 1) + 1;
  const end = page === pageCount ? totalItems : start + pageSize - 1;
  return (
    <PageInfo>
      Showing {start} - {end} of {totalItems}
    </PageInfo>
  );
};

const renderPaginator = (
  currentPage: number,
  pageSize: number,
  totalPages: number,
  callback: (page: number, itemsPerPage: number) => void,
) => {
  if (totalPages <= 0) {
    return null;
  }

  return (
    <Paginator
      currentPage={currentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      onPageClick={callback}
    />
  );
};

const Movies = () => {
  const [state, dispatch] = useReducer(moviesReducer, moviesDefaultState);
  const lastMovieAdded = useAppSelector(selectLastMovieAdded);
  const pageOptions: PaginationOptions = {
    order: 'ASC',
    page: state.page.currentPage,
    pageSize: state.page.pageSize,
  };

  useEffect(() => {
    getStatuses(pageOptions, dispatch);
  }, []);

  useEffect(() => {
    if (lastMovieAdded && state.statuses.length <= 0) {
      getStatuses(pageOptions, dispatch);
      return;
    }

    if (
      lastMovieAdded &&
      state.selectedStatus &&
      state.selectedStatus.order === 0
    ) {
      getMoviesByStatus(state.selectedStatus.id, pageOptions, dispatch);
    }
  }, [lastMovieAdded]);

  const setStatusById = async (id: string) => {
    const targetStatus = state.statuses.find((s) => s.id === id);
    if (
      (state.selectedStatus && state.selectedStatus.id === id) ||
      !targetStatus
    ) {
      return;
    }
    await changeStatus(
      targetStatus,
      { pageSize: pageOptions.pageSize, order: pageOptions.order },
      dispatch,
    );
  };

  const renderStatusSelector = () => {
    if (!state.selectedStatus) {
      return null;
    }

    return (
      <Statuses>
        <Tabs
          value={state.selectedStatus.id}
          onChange={(v) => setStatusById(v as string)}
        >
          {state.statuses.map((s) => (
            <TabLink value={s.id} key={s.id}>
              {s.displayName}
            </TabLink>
          ))}
        </Tabs>
      </Statuses>
    );
  };

  const handleReaction = (r: EmojiType) => {
    if (state.movieModal === null) {
      return;
    }
    const movieStatus = state.movieModal.status.order;
    const movieId = state.movieModal.id;

    if (movieStatus === 0 || movieStatus === 2) {
      addReaction(r, movieId, state.selectedStatus, pageOptions, dispatch);
    }

    if (movieStatus === 1 && r === 'checkMark') {
      markAsWatched(
        movieId,
        state.statuses,
        state.selectedStatus,
        pageOptions,
        dispatch,
      );
    }
    dispatch(moviesActions.setMovieModal(null));
  };

  const onPageEvent = (page: number, pageSize: number) => {
    if (state.selectedStatus) {
      getMoviesByStatus(
        state.selectedStatus.id,
        { page, pageSize, order: 'ASC' },
        dispatch,
      );
    }
  };

  return (
    <Wrapper className="page-content-child">
      {renderStatusSelector()}
      <MovieList>
        <MovieListScroll>
          {renderPageInfo(
            state.page.currentPage,
            state.page.pageSize,
            state.page.totalCount,
            state.page.pageCount,
          )}
          {state.movies.map((m) => (
            <MovieCard
              onAddReactionClick={() =>
                dispatch(moviesActions.setMovieModal(m))
              }
              movie={m}
              key={m.id}
            />
          ))}
          {renderPaginator(
            state.page.currentPage,
            state.page.pageSize,
            state.page.pageCount,
            onPageEvent,
          )}
        </MovieListScroll>
      </MovieList>
      <ReactionModal
        onBackdropClick={() => dispatch(moviesActions.setMovieModal(null))}
        movie={state.movieModal}
        onReaction={handleReaction}
      />
    </Wrapper>
  );
};

export default Movies;
