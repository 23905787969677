import Color from 'color';

const theme = {
  primary: {
    p900: Color('#02595e'),
    p600: Color('#0495a0'),
    p500: Color('#27edfe'),
    p400: Color('#7dd4ea'),
  },
  accent: {
    a900: Color('#43037b'),
    a600: Color('#6a12b8'),
    a500: Color('#af55f8'),
    a400: Color('#c083f5'),
  },
  tertiary: {
    t500: Color('#ff94d8'),
  },
  neutral: {
    black: Color('#06121a'),
    n900: Color('#152031'),
    n800: Color('#22283d'),
    n700: Color('#272d40'),
    n600: Color('#393b53'),
    n500: Color('#494b66'),
    n400: Color('#535b6d'),
    n300: Color('#737a8b'),
    n200: Color('#8e969a'),
    n100: Color('#bdc1c6'),
    white: Color('#fff'),
  },
  red: {
    r600: Color('#bd2a2a'),
    r500: Color('#de3e3e'),
    r400: Color('#ec5e5e'),
    r100: Color('#e38686'),
  },
  blue: {
    b500: Color('#58a1f5'),
  },
};

export const colorStates = {
  primary: {
    disabled: theme.primary.p900,
    default: theme.primary.p500,
    focus: theme.primary.p400,
    hover: theme.primary.p600,
  },
  accent: {
    disabled: theme.accent.a900,
    default: theme.accent.a500,
    focus: theme.accent.a400,
    hover: theme.accent.a600,
  },
  danger: {
    disabled: theme.red.r100,
    default: theme.red.r500,
    focus: theme.red.r600,
    hover: theme.red.r400,
  },
};

export const rgba = (c: Color, alpha = 0) => {
  const { r, g, b } = c.object();
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default theme;
