import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import ROUTES from '../../routes';
import useRouteAuthInfo from '../../hooks/useRouteAuthInfo';
import Logo from '../Logo';

const LoadingWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(21, 32, 49);
`;

const AuthRoute: React.FunctionComponent<RouteProps> = (props) => {
  const { children } = props;
  const { targetRoute, isLoggedIn, isFetchingUser } = useRouteAuthInfo();

  if (!isLoggedIn) {
    return <Redirect to={`${ROUTES.login}?redirect=${targetRoute}`} />;
  }

  if (isFetchingUser) {
    return (
      <LoadingWrapper>
        <Logo size="large" animate />
      </LoadingWrapper>
    );
  }

  return <Route {...props}>{children}</Route>;
};

export default AuthRoute;
