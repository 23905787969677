import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

interface Props {
  imageLink: string | null;
  alt: string;
  children: string;
}

const COLORS = ['#b2135d', '#704bc6', '#24af63', '#ce5056'];

interface WrapperProps {
  colorFill?: string;
}

const AvatarWrapper = styled.div<WrapperProps>`
  height: 32px;
  width: 32px;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${({ colorFill }) => colorFill || 'revert'};
  color: ${theme.neutral.n100.hex()};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;

  img {
    width: 100%;
  }
`;

const pickColor = (s: string) => {
  const arr = s.split('');
  const num = arr.reduce((sum, e) => e.charCodeAt(0) + sum, 0);
  return COLORS[num % COLORS.length];
};

const Avatar = (props: Props) => {
  const { imageLink, children, alt } = props;

  if (!imageLink) {
    return (
      <AvatarWrapper colorFill={pickColor(children)}>
        {children.charAt(0).toUpperCase()}
      </AvatarWrapper>
    );
  }

  return (
    <AvatarWrapper>
      <img src={imageLink} alt={alt} />
    </AvatarWrapper>
  );
};

export default Avatar;
