import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import {
  addReaction,
  getStatuses,
  markAsWatched,
  searchMovies,
  searchMoviesActions,
} from './actions';
import { defaultSearchMoviesState, searchMoviesReducer } from './reducer';
import Input from '../../components/Input';
import theme from '../../theme';
import debounce from '../../util/debounce';
import StatusMovieList from './components/StatusMovieList';
import { EmojiType } from '../../components/Emoji';
import ReactionModal from '../Movies/components/ReactionModal';
import { MovieResponse } from '../../../server/shared/movie';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  background-color: ${theme.neutral.n900.hex()};
  color: white;
  font-size: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 8px 0;
`;

const MovieList = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const MovieListScroll = styled.div`
  max-height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 12px 24px 12px;
`;

const debouncedSearch = debounce(searchMovies, 350);

const SearchMovies = () => {
  const [state, dispatch] = useReducer(
    searchMoviesReducer,
    defaultSearchMoviesState,
  );

  useEffect(() => {
    getStatuses(dispatch);
  }, []);

  useEffect(() => {
    if (state.query) {
      debouncedSearch(state.query, dispatch);
    }
  }, [state.query]);

  const handleReaction = (r: EmojiType) => {
    if (state.movieModal === null) {
      return;
    }
    const movieStatus = state.movieModal.status.order;
    const movieId = state.movieModal.id;

    if (movieStatus === 0 || movieStatus === 2) {
      addReaction(r, movieId, state.query, dispatch);
    }

    if (movieStatus === 1 && r === 'checkMark') {
      markAsWatched(movieId, state.query, state.statuses, dispatch);
    }
    dispatch(searchMoviesActions.setMovieModal(null));
  };

  const openMovieModal = (movie: MovieResponse) =>
    dispatch(searchMoviesActions.setMovieModal(movie));

  return (
    <Wrapper className="page-content-child">
      <InputWrapper>
        <Input
          label="Search"
          name="searchMovies"
          placeholder="Search for a movie"
          value={state.query}
          onChange={(v) => dispatch(searchMoviesActions.setQuery(v))}
          error={state.query.length > 0 ? state.searchMoviesError : null}
        />
      </InputWrapper>

      <MovieList>
        <MovieListScroll>
          {state.movies.map((m) => (
            <StatusMovieList statusList={m} openMovieModal={openMovieModal} />
          ))}
        </MovieListScroll>
      </MovieList>
      <ReactionModal
        onBackdropClick={() =>
          dispatch(searchMoviesActions.setMovieModal(null))
        }
        movie={state.movieModal}
        onReaction={handleReaction}
      />
    </Wrapper>
  );
};

export default SearchMovies;
