import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../components/Logo';
import useQueryParams from '../../hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { selectSessionState } from '../../selectors/sessionSelectors';
import { login } from '../../actions/sessionActions';
import ROUTES from '../../routes';

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(21, 32, 49);
`;

const ErrorMessage = styled.p`
  color: tomato;
`;

const TOKEN_QUERY_PARAM = 'token';

const Login = () => {
  const queryParams = useQueryParams();
  const token = queryParams.get(TOKEN_QUERY_PARAM) || null;
  const sessionState = useAppSelector(selectSessionState);
  const reduxDispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (
      token &&
      !sessionState.tokenLoading &&
      !sessionState.token &&
      !sessionState.tokenError
    ) {
      reduxDispatch(login(token));
    }
  }, [
    token,
    sessionState.tokenLoading,
    sessionState.token,
    sessionState.tokenError,
  ]);

  useEffect(() => {
    if (sessionState.token) {
      history.replace(ROUTES.movies);
    }
  }, [sessionState.token]);

  return (
    <Wrapper>
      <Logo size="large" animate={sessionState.tokenLoading} />
      <ErrorMessage>{sessionState.tokenError}</ErrorMessage>
    </Wrapper>
  );
};

export default Login;
