import actionCreator, { Actions } from '../util/actionCreator';
import { TokenResponse } from '../../server/shared/token';
import { AppThunk } from '../store';
import { UserResponse } from '../../server/shared/user';
import { ApiConfig, isErrorResponse, TokenApi, UsersApi } from '../util/api';

const GET_TOKEN_START = 'GET_TOKEN_START';
const GET_TOKEN_END = 'GET_TOKEN_END';
const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';
const GET_CURRENT_USER_START = 'GET_CURRENT_USER_START';
const GET_CURRENT_USER_END = 'GET_CURRENT_USER_END';
const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR';

export const sessionActions = {
  getTokenStart: () => actionCreator(GET_TOKEN_START),
  getTokenEnd: (payload: TokenResponse) =>
    actionCreator(GET_TOKEN_END, payload),
  getTokenError: (error: string) => actionCreator(GET_TOKEN_ERROR, error),
  getCurrentUserStart: () => actionCreator(GET_CURRENT_USER_START),
  getCurrentUserEnd: (user: UserResponse) =>
    actionCreator(GET_CURRENT_USER_END, user),
  getCurrentUserError: (error: string) =>
    actionCreator(GET_CURRENT_USER_ERROR, error),
};

export type SessionActions = Actions<typeof sessionActions>;

export const getCurrentUser = (): AppThunk => async (dispatch) => {
  dispatch(sessionActions.getCurrentUserStart());
  const response = await UsersApi.getCurrentUser();
  if (isErrorResponse(response)) {
    dispatch(sessionActions.getCurrentUserError(response.statusText));
    return;
  }
  dispatch(sessionActions.getCurrentUserEnd(response));
};

export const login =
  (token: string): AppThunk =>
  async (dispatch) => {
    dispatch(sessionActions.getTokenStart());
    ApiConfig.headers = {
      authorization: `Bearer ${token}`,
    };
    const response = await TokenApi.create();
    ApiConfig.headers = undefined;
    if (isErrorResponse(response)) {
      dispatch(sessionActions.getTokenError(response.statusText));
      return;
    }
    window.localStorage.setItem('token', JSON.stringify(response));
    dispatch(sessionActions.getTokenEnd(response));
  };
