import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../Icon';
import theme, { rgba } from '../../theme';
import NewMovieOverlay from '../NewMovieOverlay';

const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  background-color: ${theme.neutral.black.hex()};
  padding: 8px 24px;
  position: relative;
`;

const NavLink = styled(Link)`
  display: flex;
  border-radius: 4px;
  padding: 8px;
  align-items: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  justify-content: center;

  p {
    font-size: 12px;
    flex: 0;
    transform-origin: left;
    overflow: hidden;
    margin: 0;
    color: ${theme.primary.p500.hex()};
    font-weight: 600;
    transition: flex 150ms ease;
  }

  svg {
    fill: ${theme.neutral.white.hex()};
  }

  &.bottom-nav__link--active {
    p {
      margin-left: 4px;
      flex: 1;
    }
  }

  &:hover,
  &.bottom-nav__link--active {
    background-color: ${rgba(theme.primary.p500, 0.1)};

    svg {
      fill: ${theme.primary.p500.hex()};
    }
  }
`;

const Fab = styled.button`
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 4px;
  border: 0;
  border-radius: 50%;
  left: calc(50% - 18px);
  bottom: 40%;
  background: linear-gradient(
    72deg,
    ${theme.tertiary.t500.hex()} 0%,
    ${theme.accent.a500.hex()} 40%,
    ${theme.primary.p500.hex()} 100%
  );
  cursor: pointer;

  svg {
    fill: ${theme.neutral.black.hex()};
  }
`;

const BottomNav = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Wrapper>
      <NavLink to="/movies" activeClassName="bottom-nav__link--active">
        <Icon icon="movie" />
        <p>Movies</p>
      </NavLink>
      <Fab onClick={() => setModalOpen(true)}>
        <Icon icon="add" size="medium" color="dark" />
      </Fab>
      <NavLink to="/search" activeClassName="bottom-nav__link--active">
        <Icon icon="search" />
        <p>Search</p>
      </NavLink>
      <NewMovieOverlay
        show={modalOpen}
        onBackdropClick={() => setModalOpen(false)}
      />
    </Wrapper>
  );
};

export default BottomNav;
