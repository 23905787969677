import { MoviesWithStatus, SearchMoviesActions } from './actions';
import * as at from './actionTypes';
import { StatusResponse } from '../../../server/shared/status';
import { MovieResponse } from '../../../server/shared/movie';

interface SearchMovieState {
  query: string;
  searchMoviesLoading: boolean;
  movies: MoviesWithStatus[];
  searchMoviesError: string | null;
  getStatusesLoading: boolean;
  statuses: StatusResponse[];
  getStatusesError: string | null;
  addReactionLoading: boolean;
  addReactionError: string | null;
  updateMovieLoading: boolean;
  updateMovieError: string | null;
  movieModal: MovieResponse | null;
}

export const defaultSearchMoviesState: SearchMovieState = {
  query: '',
  searchMoviesLoading: false,
  movies: [],
  searchMoviesError: null,
  getStatusesLoading: false,
  statuses: [],
  getStatusesError: null,
  addReactionLoading: false,
  addReactionError: null,
  updateMovieLoading: false,
  updateMovieError: null,
  movieModal: null,
};

export const searchMoviesReducer = (
  state: SearchMovieState,
  action: SearchMoviesActions,
): SearchMovieState => {
  switch (action.type) {
    case at.SEARCH_MOVIES_START:
      return {
        ...state,
        searchMoviesLoading: true,
      };
    case at.SEARCH_MOVIES_END:
      return {
        ...state,
        searchMoviesLoading: false,
        searchMoviesError: null,
        movies: action.payload,
      };
    case at.SEARCH_MOVIES_ERROR:
      return {
        ...state,
        searchMoviesLoading: false,
        searchMoviesError: action.payload,
        movies: [],
      };
    case at.SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case at.GET_STATUSES_START:
      return {
        ...state,
        getStatusesLoading: true,
      };
    case at.GET_STATUSES_END:
      return {
        ...state,
        getStatusesLoading: false,
        statuses: action.payload,
        getStatusesError: null,
      };
    case at.GET_STATUSES_ERROR:
      return {
        ...state,
        getStatusesLoading: false,
        statuses: [],
        getStatusesError: action.payload,
      };
    case at.ADD_REACTION_START:
      return {
        ...state,
        addReactionLoading: true,
      };
    case at.ADD_REACTION_END:
      return {
        ...state,
        addReactionLoading: false,
        addReactionError: null,
      };
    case at.ADD_REACTION_ERROR:
      return {
        ...state,
        addReactionLoading: false,
        addReactionError: action.payload,
      };
    case at.UPDATE_MOVIE_START:
      return {
        ...state,
        updateMovieLoading: true,
      };
    case at.UPDATE_MOVIE_END:
      return {
        ...state,
        updateMovieLoading: false,
        updateMovieError: null,
      };
    case at.UPDATE_MOVIE_ERROR:
      return {
        ...state,
        updateMovieLoading: false,
        updateMovieError: action.payload,
      };
    case at.SET_MOVIE_MODAL:
      return {
        ...state,
        movieModal: action.payload,
      };
    default:
      return state;
  }
};
